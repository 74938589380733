<template>
  <v-card flat>
    <toolbar :title="editMode ? $t('role.edit') : $t('role.new')" back-button>
      <v-btn
        :disabled="!valid"
        :dark="valid"
        small
        text
        color="success"
        class="mx-2"
        @click="save"
      >
        <v-icon>mdi-content-save</v-icon>
        <span class="text--secondary">{{ $t("general.save") }}</span>
      </v-btn>
    </toolbar>

    <v-card-text class="px-0 py-0">
      <v-form ref="form" v-model="valid" class="py-5 px-5">
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="item.name"
              :counter="200"
              :rules="rules.name"
              :label="$t('general.name')"
              required
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-card tile>
              <v-sheet
                tile
                height="40"
                color="secondary"
                class="pa-2 d-flex justify-space-between"
              >
                <div class="v-label ma-1">
                  {{ $t("role.permissions") }}
                </div>
                <div>
                  <v-btn
                    small
                    depressed
                    color="primary"
                    class="px-1 mr-1"
                    @click="selectAll"
                  >
                    ALL
                  </v-btn>
                  <v-btn
                    small
                    depressed
                    color="primary"
                    class="px-1"
                    @click="discardAll"
                  >
                    NONE
                  </v-btn>
                </div>
              </v-sheet>
              <v-list dense v-model="permissions">
                <v-list-item-group color="primary">
                  <div v-for="(prm, i) in permissions" :key="i">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title v-text="prm.name" />
                        <v-list-item-subtitle v-text="prm.description" />
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-switch
                          v-model="selectedPermissions"
                          :value="prm.id"
                        />
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider></v-divider>
                  </div>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-col>
        </v-row>

        <br />
        <v-divider />
        <v-btn
          :disabled="!valid"
          :dark="valid"
          color="success"
          class="mr-4"
          tile
          depressed
          @click="save"
        >
          <v-icon>mdi-content-save</v-icon>
          {{ $t("general.save") }}
        </v-btn>

        <v-btn color="primary" tile depressed @click="reset">
          <v-icon>mdi-undo-variant</v-icon>
          {{ $t("general.undo") }}
        </v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import Toolbar from "@/common/components/Toolbar";

export default {
  name: "RoleForm",
  components: { Toolbar },
  props: {
    data: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },
    permissions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      item: {
        id: 0,
        name: "",
      },
      selectedPermissions: [],
      valid: false,
      originalState: { id: 0 },
      rules: {
        name: [
          (v) => !!v || "Name is required",
          (v) => v.indexOf(" ") === -1 || "No whitespaces",
        ],
      },
    };
  },
  watch: {
    data: {
      handler: function(n) {
        if (n) {
          this.item = { ...n };
          this.selectedPermissions = n.permissions
            ? n.permissions.map((m) => m.id)
            : [];
          this.originalState = { ...n };
        }
      },
    },
  },
  computed: {
    changed() {
      return this.item && !this.compareObjects(this.item, this.originalState);
    },

    idParam() {
      return parseInt(this.$route.params.id);
    },
    editMode() {
      return this.idParam > 0;
    },
  },
  methods: {
    save() {
      const item = { ...this.item, permissions: this.selectedPermissions };
      this.$emit("save", { item, editMode: this.editMode });
    },
    reset() {
      this.permissions = [];
      this.item = { ...this.originalState };
    },
    selectAll() {
      this.selectedPermissions = this.permissions.map((m) => m.id);
    },
    discardAll() {
      this.selectedPermissions = [];
    },
  },
};
</script>

<style scoped>
.text-deleted {
  text-decoration: line-through;
}
</style>
